@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Lora:400,700|Montserrat:300');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' 'Avenir Next',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(to right, rgba(255, 228, 230, 0.6), rgba(204, 251, 241, 0.6)); */
  /* bg-gradient-to-r from-rose-100 to-teal-100 */
  background-color: #102A27;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#STROKE_0ff8eb49-b427-4536-9cf6-e0a623b0b6b2,
#STROKE_e7046d31-d54d-439b-b887-d807840622c1,
#STROKE_e259811f-339e-49b5-b2f2-ea9ba80e8d9b,
#STROKE_0ee805b5-4856-4e9c-8095-b369dd006b6c,
#STROKE_94cf6935-8a5d-4af9-8548-237b1968d0b3,
#STROKE_d380b6a8-cec8-4348-8b0d-a05f8fa88da2,
#STROKE_ce8f1881-6c73-486e-b960-bc6077295b2d,
#STROKE_b6326550-e8f2-4dff-b37c-79f1d9ba79d3,
#STROKE_404c27dd-7497-4239-9d11-25d9ef436b77,
#STROKE_c5349e91-3c11-4509-97ff-cbf1d0a163b3,
#STROKE_d57f5ded-29de-42d6-9db2-d8075d3ef335,
#STROKE_9b65df48-39fd-49ae-a193-22d620050991,
#STROKE_2dc08227-7732-4b8b-97a7-f60b4deff1d0,
#STROKE_7e4eabf4-617b-4a06-bab6-eece3900055d,
#STROKE_8caf9871-04dc-438d-b65b-f6a0df2524c9,
#STROKE_c032603b-0157-482d-84df-98e8cf226349,
#STROKE_ffe18e8a-1183-48e4-ab86-0241310c97cf
{
  stroke: #0b7057;
  /* color: #fae5e6 */
  opacity: 0.100;
}